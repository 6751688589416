import { isMobile } from "utils/mobile-check";
import Link from "next/link";
import { TopRow, MobileButtons, BottomRow, Indicator } from "./styles";
import { useRef, useState } from "react";

export type Props = {
  setPage?: (page: number) => void;
  page: number;
  numberOfPages: number;
  href: string;
};

/**
 * Controls paginated pages
 */
export function PageNavigator({ setPage, numberOfPages, href, page }: Props) {
  const bottom = Math.max(0, page - 4);
  const top = Math.min(numberOfPages, bottom + 9);
  const length = top - bottom;

  const [hovering, setHovering] = useState<number | undefined>();
  const timeout = useRef<NodeJS.Timeout | number>();
  const handleMouseOver = (page: number) => () => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    setHovering(page);
  };
  const handleMouseOut = () => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    timeout.current = setTimeout(setHovering, 400);
  };

  const pages = Array.from({ length }).map((_, i: number) => {
    const current = i + bottom;
    return (
      <Link
        href={`${href}/${current + 1}`}
        passHref
        key={`pageNavigator-${current}`}
      >
        <Indicator
          disabled={page === current}
          onClick={() => (setPage ? setPage(current) : null)}
          onMouseOver={handleMouseOver(current)}
          onMouseOut={handleMouseOut}
          className={hovering === current ? "hovering" : ""}
        >
          <span>{hovering === current ? current + 1 : null}</span>
        </Indicator>
      </Link>
    );
  });

  return numberOfPages > 1 ? (
    <>
      <TopRow>{!isMobile() && pages}</TopRow>
      <BottomRow>
        {page + 1}/{numberOfPages}
      </BottomRow>
      <MobileButtons>
        <Link href={`${href}/${Math.max(page - 1, 1)}`} passHref>
          <button className="left" disabled={page < 2}>
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{ marginLeft: -1 }}
            >
              <path
                fill="#fff"
                d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
              ></path>
            </svg>
          </button>
        </Link>
        <Link href={`${href}/${Math.min(page + 2, numberOfPages)}`} passHref>
          <button className="right">
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{ marginRight: -1 }}
            >
              <path
                fill="#fff"
                d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
              ></path>
            </svg>
          </button>
        </Link>
      </MobileButtons>
    </>
  ) : (
    <></>
  );
}
