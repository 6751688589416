import styled from "@emotion/styled";
import { Props } from ".";

type StyleProps = Pick<Props, "primary" | "justifySelf">;

export const CardRoot = styled.article<StyleProps>(
  ({ theme, primary, justifySelf }) => `
    /** Setup css vars for easy switching between */
    --primary-card-width: 100%;
    --default-card-width: 382px;
    --card-width: var(--${primary ? "primary" : "default"}-card-width);

    --primary-border-radius: 10px 0 0 10px;
    --default-border-radius: 10px 10px 0 0;
    --picture-border-radius: var(
      --${primary ? "primary" : "default"}-border-radius
    );

    --primary-picture-height: "100%";
    --default-picture-height: "200px";
    --picture-height: var(--${primary ? "primary" : "default"}-picture-height);

    --card-shadow: 1px 3px 5px 0px #0000002e;

    display: flex;
    max-width: var(--card-width);
    color: ${theme.colors.fontPrimary};
    flex-flow: column wrap;
    box-shadow: var(--card-shadow);
    border-radius: 10px;
    margin-bottom: 40px;
    transition: all 0.1s ease;
    cursor: pointer;

    /* Extra specificity */
    &&& {
      ${justifySelf && `justify-self: ${justifySelf};`};
      ${primary && `grid-column: 1 / 3;`};

      @media (max-width: 650px) {
        grid-column: 1 / 3;
        justify-self: center;
        padding: 0;
        flex-direction: row;
        /** Set card widths to all be the same */
        --card-width: calc(100% - 30px);
        picture {
          height: var(--default-picture-height);
          border-radius: var(--default-border-radius);
        }
      }
    }

    &:hover {
      --card-shadow: 5px 5px 8px 1px #0000002e;
      transform: translate(-1px, -1px);
    }

    @media (min-width: 769px) {
      flex-flow: ${primary ? `row nowrap` : `column wrap`};
    }

    picture {
      display: block;
      width: 100%;
      height: var(--picture-height);
      background-color: #d8d8d8;
      border-radius: var(--picture-border-radius);
      display: flex;
      overflow: hidden;

      img {
        object-fit: cover;
        overflow: hidden;
        height: 100%;
      }
    }

    .details {
      text-align: left;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 10px;
      ${
        primary &&
        `
      @media (min-width: 769px) {
        max-width: 480px;
        min-width: 350px;
        margin-left: 20px;
      }
      `
      };
    }

    .small {
      font-size: 13px;
      margin: 10px 0;
    }

    .x-small {
      font-size: 11px;
      letter-spacing: -0.165px;
      color: #bebebe;
    }

    .summary {
      font-size: 14px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      height: 54px;
    }

    header {
      margin: 5px 0 10px;
      h2 {
        margin: 0;
      }
    }

    address {
      flex: 1;
      display: flex;
      align-items: flex-end;
      color: #bebebe;
      margin-top: 20px;

      div {
        width: 100%;
        border-top: 1px solid #bebebe;
        text-align: left;
        padding: 8px 0;
      }

      .author {
        color: #bebebe;
        width: 100%;
      }
    }
  `
);
