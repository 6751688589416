import styled from "@emotion/styled";

export const Heading = styled.header`
  width: 100%;
  max-height: 37vw;
  background-color: #24252a;
  color: #fff;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: 75px 0;
  text-align: center;

  h1 {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.34px;
    line-height: 1.2;
    max-width: 11em;
  }

  @media screen and (min-width: 40em) {
    h1 {
      font-weight: 500;
      font-size: 48px;
      letter-spacing: -0.67px;
    }
  }
`;

export default Heading;
