import { memo } from "react";
import Link from "next/link";
import { IBlogPost } from "../BlogPosts/types";
import { CardRoot } from "./styles";

export type Props = Omit<IBlogPost, "slug" | "body"> & {
  primary: boolean;
  category?: string;
  justifySelf?: "flex-start" | "flex-end";
};

const Card = ({
  primary,
  heroImage,
  publishDate,
  title,
  description,
  author,
  category,
  justifySelf,
}: Props) => {
  const localizedDate = publishDate ? new Date(publishDate).toDateString() : "";
  const authorText = `article written by `;
  return (
    <CardRoot primary={primary} justifySelf={justifySelf}>
      <picture>
        {heroImage && heroImage.fields && heroImage.fields.file && (
          <>
            <source
              srcSet={`${heroImage.fields.file.url}?w=800&fm=webp&q=80`}
            />
            <img
              src={`${heroImage.fields.file.url}?w=800`}
              alt={heroImage.fields.title}
            />
          </>
        )}
      </picture>
      <div className="details">
        <div>
          <div className="x-small">
            <time dateTime={publishDate.toString()}>{`${localizedDate} `}</time>
            {category && (
              <a itemScope itemType="http://schema.org/articleSection">
                {category}
              </a>
            )}
          </div>
          <header>
            <h4>{title}</h4>
          </header>
          <div className="summary">{description}</div>
        </div>
        {author && (
          <address className="author">
            <div>
              {authorText}
              <Link href={`/insights/authors/${author.slug}`}>
                <a className="secondary">{author.name}</a>
              </Link>
            </div>
          </address>
        )}
      </div>
    </CardRoot>
  );
};

export default memo(Card);
