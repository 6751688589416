import styled from "@emotion/styled";

export const Grid = styled.div`
  display: grid;
  justify-items: center;
  padding: 0 15px;
  grid-template-columns: repeat(10, 1fr);
  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
    grid-template-columns: auto;
  }
  grid-column-gap: 26.6px;
  grid-template-rows: auto auto auto;
  width: 100%;
  max-width: 1364px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export default Grid;
