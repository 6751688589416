import styled from "@emotion/styled";

export const Indicator = styled.button(
  ({ theme }) => `
    --width: 25px;

    // increase specificity
    && {
      width: var(--width);
      height: var(--width);
      margin: 2px;
      margin-bottom: 10px;
      padding: 0;
      position: relative;
      border: none;
      box-sizing: content-box;
      color: white;
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      transition: all 0.4s ease;
      width: var(--width);
      background-color: ${theme.colors.secondaryHover};
    }

    &:disabled:before {
      background-color: ${theme.colors.highlight};
      cursor: default;
    }

    &&.hovering {
      &:before {
        height: 100%;
      }
      span {
        position: absolute;
        top: 2px;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
      }
    }
  `
);

export const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 13px 0 0;
  grid-column: row-start / row-end;
`;

export const BottomRow = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: flex;
    justify-content: center;
    font-weight: bold;
    grid-column: row-start / row-end;
  }
`;

export const MobileButtons = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    grid-column: row-start / row-end;
    @media (min-width: 769px) {
      display: none;
    }

    button {
      display: inline-block;
      height: 37px;
      width: 37px;
      border-radius: 50%;
      background-color: ${theme.colors.highlight};
      border: none;
      margin: 3.5px;
      color: white;
      padding: 0;

      &:disabled {
        background-color: rgb(216, 216, 216);
        cursor: default;
      }
    }
  `
);
