let gaInitialised = true;

const categories = {
  search: "Search",
  ticker: "Ticker",
  event: "Event",
  savedSearch: "Saved Search",
  eventCarousel: "Event carousel",
  latestVacancy: "Latest Vacancies",
  latestNews: "Latest News",
  breakingNews: "Breaking News",
  companySpotlight: "Company Spotlight",
  landingPage: "Landing Page",
  blogPost: "Blog Post",
  searchResults: "Search Results",
};

const categoryMap = {
  news: "News",
  vacancies: "Vacancies",
  saved: "Saved Searches",
  ["market-mapping"]: "Market Mapping",
};

const typeMap = {
  type: "Type",
  trend: "Trend",
  date: "Date",
};

const actions = {
  trend: "Hiring/Firing",
  date: "Date Posted",
  type: "Type",
};

const sendEvent = (category, action, label, value) => {
  if (!gaInitialised) return;

  if (!global.ga) {
    gaInitialised = false;
    if (process.browser) {
      console.warn("Google analytics not initialised, no events will be sent!");
    }
    return;
  }

  if (!category || !action) {
    console.error("sendEvent requires category and action to be defined");
    return;
  }

  let event = {
    hitType: "event",
    eventCategory: category,
    eventAction: action,
  };
  label && (event["eventLabel"] = label);
  value && (event["eventValue"] = value);

  global.ga("send", event);
};

export const clickedOnTickerCompany = (company, userId) => {
  sendEvent(categories.ticker, "Company Clicked", company, userId);
};

export const changedSearchFilterTab = (category, type) => {
  sendEvent(categories.search, categoryMap[category], typeMap[type]);
};

export const changedResultsFilter = (category, action, option, userId) => {
  sendEvent(categoryMap[category], actions[action], option, userId);
};

export const openedEvent = (eventType) => {
  sendEvent(categories.event, "Open", categoryMap[eventType]);
};

export const largeCarouselClicked = (eventId, userId) => {
  sendEvent(categories.eventCarousel, "Large event clicked", eventId, userId);
};

export const smallCarouselClicked = (eventId, userId) => {
  sendEvent(categories.eventCarousel, "Small event clicked", eventId, userId);
};

export const latestVacancyClicked = (eventId, userId) => {
  sendEvent(categories.latestVacancy, "Vacancy clicked", eventId, userId);
};

export const seeAllVacancies = (userId) => {
  sendEvent(
    categories.latestVacancy,
    "See all Vacancies clicked",
    "See all Vacancies",
    userId
  );
};

export const openedLatestNews = (eventId, userId) => {
  sendEvent(categories.latestNews, "News Clicked", eventId, userId);
};

export const seeAllNews = (userId) => {
  sendEvent(
    categories.latestNews,
    "See all news clicked",
    "See all news",
    userId
  );
};

export const breakingNewsClicked = (eventId, userId) => {
  sendEvent(categories.breakingNews, "Breaking news Clicked", eventId, userId);
};

export const compEventClicked = (eventId, userId) => {
  sendEvent(
    categories.companySpotlight,
    "Latest event analysis Clicked",
    eventId,
    userId
  );
};

export const relatedEventClicked = (eventId, userId) => {
  sendEvent(categories.event, "Related event clicked", eventId, userId);
};

export const careersPageClicked = (company, userId) => {
  sendEvent(
    categories.companySpotlight,
    "Careers page Clicked",
    company,
    userId
  );
};

export const blogPostClicked = (title, userId) => {
  sendEvent(categories.blogPost, "Blog post Clicked", title, userId);
};

export const ctaClicked = (title, userId) => {
  sendEvent("CTA2", "Clicked", title, userId);
};

export const savedSearchSelected = (searchId, userId) => {
  sendEvent(categories.savedSearch, "Search selected", searchId, userId);
};

export const searchSaved = (searchId, userId) => {
  sendEvent(categories.savedSearch, "Search saved", searchId, userId);
};

export const limitedResults = (chips, userId, type) => {
  sendEvent(categories.landingPage, `Low ${type} Results`, chips, userId);
};
