import styled from "@emotion/styled";

export const Main = styled.main(
  ({ theme }) =>
    `
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      background-color: white;
      min-height: calc(100vh - 80px);
      font-weight: 300;
      line-height: 1.29;
      letter-spacing: -0.18px;
      position: relative;

      p {
        font-size: 18px;
      }

      address {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        text-align: center;
        color: #ffffff;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        b {
          color: ${({ theme }) => theme.colors.highlight};
        }
      }

      h1 {
        font-size: 65px;
        font-weight: bold;
        line-height: 1;
        margin: 20px auto;
        max-width: 9em;

        @media ${({ theme }) => theme.breakpoints.mobile} {
          font-size: 37px;
          max-width: 15em;
          margin: 8px auto;
        }
      }

      h2 {
        font-size: 45px;
        margin: 24px 0;
        @media ${({ theme }) => theme.breakpoints.mobile} {
          font-size: 37px;
          max-width: 15em;
        }
      }

      h3 {
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
        margin: 0;

        @media ${({ theme }) => theme.breakpoints.mobile} {
          font-size: 37px;
          max-width: 15em;
          margin: 8px 0;
        }
      }

      h4 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 1.21;
        margin: 15px 0;

        @media ${({ theme }) => theme.breakpoints.mobile} {
          font-size: 24px;
        }
      }

      h5 {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 1.21;
        margin: 0;

        @media ${({ theme }) => theme.breakpoints.mobile} {
          font-size: 20px;
        }

        .stay-tall {
          @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 38px;
          }
        }
      }

      .left {
        h2 {
          max-width: 15em;
        }
      }

      a {
        color: ${theme.colors.highlight};
        &.secondary {
          color: #7aeba8;
        }
      }
      button {
        color: ${theme.colors.highlight};
      }
      button,
      .button-placeholder {
        border: none;
        background: none;
        padding: 1em;
        font-size: 16px;
        font-weight: 600;
      }

      .homepage-button {
        background-color: ${theme.colors.highlight};
        color: ${theme.colors.white};
        font-size: 22px;
        height: 60px;
        border-radius: 30px;
        padding: 0 30px;
        display: flex;
        align-items: center;

        img {
          height: 30px;
        }
      }

      .page-section {
        .inner {
        }
      }

      .address-section {
        padding: 0 10vw;
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        p {
          margin: 0;
        }
        .title {
          font-size: 12px;
          letter-spacing: -0.17px;
          font-weight: 300;
        }

        a {
          color: black;
        }
      }

      .address-section-emails {
        text-align: left;
        div {
          padding: 0;
        }
      }

      .weeks-ahead {
        h2 {
          padding: 40px 0;
        }

        .grid {
          @media (max-width: 1400px) {
            max-width: 800px;
            margin: 0 auto;
            grid-template-columns: [row-start] repeat(2, 1fr) [row-end];
          }

          @media ${({ theme }) => theme.breakpoints.tablet} {
            max-width: unset;
            grid-template-columns: auto;
            padding: 0;
          }
        }

        .inner {
          width: 100%;
        }
      }
    `
);
