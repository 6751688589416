/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, Fragment } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import * as authSelectors from "store/auth/selectors";
import { blogPostClicked } from "utils/ga-events";
import Link from "next/link";
import Card from "../Card";

const A = styled.a`
  display: contents;
`;

export type Props = {
  items: { fields: any; sys: { id?: string } }[];
  page: number;
  firstPrimary?: boolean;
};
function Cards({ items, page, firstPrimary }: Props) {
  const userId = useSelector(authSelectors.getUserId);

  const handleBlogPostClicked = (title: string) => () =>
    blogPostClicked(title, userId);

  return typeof items.length === "number" ? (
    <>
      {items.map(({ sys, fields = {} }, idx) => {
        const id = sys.id || "";
        const {
          title = "",
          description = "",
          slug = "",
          heroImage,
          publishDate = "",
          author = { fields: {} },
        } = fields;
        const primary = firstPrimary && idx === 0 && page === 1;
        /** A nice, very readable ternary! */
        const justifySelf =
          firstPrimary && page === 1
            ? primary
              ? undefined
              : idx % 2
              ? "flex-start"
              : "flex-end"
            : idx % 2
            ? "flex-end"
            : "flex-start";
        return (
          <Link
            href={`/insights/articles/${slug}-${id}`}
            passHref
            key={`insight-${slug}-${idx}`}
          >
            <A onClick={handleBlogPostClicked(title)}>
              <Card
                primary={primary}
                heroImage={heroImage}
                title={title}
                description={description}
                publishDate={publishDate}
                author={author.fields}
                justifySelf={justifySelf}
              />
            </A>
          </Link>
        );
      })}
    </>
  ) : (
    <Fragment />
  );
}

export default memo(Cards);
